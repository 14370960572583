import { Location } from '@angular/common';
import { DataCache } from './../../Helpers/data-cache';
import { ConfirmationService } from 'primeng/api';
import { UsersService } from './../../Services/users/users.service';
import { User } from './../../Models/User';
import { DepartmentsService } from './../../Services/departments/departments.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { TypeRoleGroup } from 'src/app/Models/role';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  EmpId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Password: string;
  ConfirmPassword: string;
  Pin: string;
  ConfirmPin: string;
  Title: string;

  languages = [];
  language: any;

  isCreate: boolean;
  loading: boolean;

  departments = [];
  department: any;

  adgroups1 = [];
  adgroups2 = [];

  isadm = false;

  Icon: any;
  icon: any;
  IconName: any;

  constructor(
    private departmentService: DepartmentsService,
    private router: Router,
    private userService: UsersService,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private groupsService: GroupsService,
    private location: Location,
    private domSanitizer: DomSanitizer
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Administration.Full')) {
          this.isadm = true;
      }
    }

    translate.get('GENERAL.ENGLISH').subscribe(en => {
      translate.get('GENERAL.FRENCH').subscribe(fr => {
        this.languages = [
          {
            label: en,
            value: 'en'
          },
          {
            label: fr,
            value: 'fr'
          }
         ];
      });
    });

    let seldept: string;
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTDEPARTMENT').subscribe((resp: string) => {
      seldept = resp;
      // this.departments.push({label: seldept + ' *', value: null});
    });
    this.getGroups();
    this.getDepartments();
   }

  ngOnInit() {
    //
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.adgroups1.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  getDepartments() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.departmentService.getDepartments().subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const val = localStorage.getItem('oi');
        const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        const orgId = +oi;
        // const orgId = +localStorage.getItem('organisation-id');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].organisationId === orgId) {
            if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
              let show = false;
              for (const va of res[i].typeRoleGroups) {
                if (va.typeRoleGroup.roleName === 'Initiator' && gids.includes(va.typeRoleGroup.groupId)) {
                  show = true;
                }
              }

              if (this.isadm === true) {
                show = true;
              }

              if (show === false) {
                continue;
              }
            } else {
              if (this.isadm === true) {
                // add trigger to list
              } else {
                continue;
              }
            }
            this.departments.push({label: res[i].departmentName, value: res[i]});
          }
        }
      }
      this.loading = false;
    });
  }

  onCancel() {
    // this.router.navigateByUrl('/users')
    this.location.back();
  }

  onCreate() {
    if (this.EmpId === undefined || this.EmpId.toString().trim() === null || this.EmpId === 0 || this.EmpId.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREMPLOYEEID').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
     } else if (isNaN(this.EmpId)) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREMPLOYEEIDMUSTBENUMBER').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
     }
    if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERFIRSTNAME').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERLASTNAME').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Email === undefined || this.Email === null || this.Email.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREMAIL').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (!this.Email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INVALIDEMAIL').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Phone === undefined || this.Phone === null || this.Phone.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPHONE').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    if (this.Title === undefined || this.Title === null || this.Title.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERTITLE').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Password === undefined || this.Password === null || this.Password.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPASSWORD').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.ConfirmPassword === undefined || this.ConfirmPassword === null || this.ConfirmPassword.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPASSWORDCONFIRMATION').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Password !== this.ConfirmPassword) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PASSWORDSDONOTMATCH').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Pin === undefined || this.Pin === null || this.Pin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPIN').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.ConfirmPin === undefined || this.ConfirmPin === null || this.ConfirmPin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPINCONFIRMATION').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Pin !== this.ConfirmPin) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PINSDONOTMATCH').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.department === undefined || this.department === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTDEPARTMENT').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    this.loading = true;
    const now = new Date();

    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const orgId = +oi;

    const user = new User();
    user.OrgId = orgId;
    user.UserId = 0;
    user.EmployeeId = this.EmpId;
    user.DepartmentId = this.department.departmentId;
    user.FirstName = this.FirstName;
    user.LastName = this.LastName;
    user.Email = this.Email;
    user.Phone = this.Phone;
    user.ConfCode = '';
    user.LastLogin = new Date(0, 0, 0, 0, 0, 0);
    user.Created = new Date(now.getFullYear(), now.getMonth(),
                            now.getDate(), now.getHours() + 1,
                            now.getMinutes(), now.getSeconds());
    user.Password = this.Password;
    user.Pin = this.Pin;
    user.UserLanguage = this.language;
    user.UserTitle = this.Title;

    user.UserImage = null;
    if (this.Icon !== undefined || this.Icon !== null || this.Icon.trim() !== '') {
      user.UserImage = this.Icon;
    }

    const rgroups = [];
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const val of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'user';

        rgroups.push(rgroup);
      }
    }

    user.RoleGroups = rgroups;

    // tslint:disable-next-line: deprecation
    this.userService.postUser(user).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERCREATED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
      });
      // this.router.navigateByUrl('/users');
      this.location.back();
      this.isCreate = false;
      this.loading = false;
    }, err => {
      this.isCreate = false;
      this.loading = false;
      this.handleError(err);
    });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.isCreate = false;
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_user_phone_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PHONENUMBERALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_user_email_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAILALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_user_emp_id_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMPLOYEEIDALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else {
      console.log(err);
    }

  }

}
