import { Location } from '@angular/common';
import { DataCache } from './../../Helpers/data-cache';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from 'src/app/Services/departments/departments.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/Services/users/users.service';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { UserUpdater, KeyValue } from 'src/app/Models/KeyValue';
import * as CryptoJS from 'crypto-js';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { Variables } from 'src/app/Helpers/variables';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user: any;

  EmpId: number;
   FirstName: string;
   LastName: string;
   Email: string;
   Phone: string;
   Title: string;

   languages = [];
   language: any;

   isSave: boolean;

   departments = [];
   department: any;

   loading = false;
   issave = true;

   gs = [];

   adgroups1 = [];
   adgroups2 = [];

   isadm = false;

   tempdepts = [];

   Icon: any;
   icon: any;
   IconName: any;

   constructor(
     private departmentService: DepartmentsService,
     private router: Router,
     private userService: UsersService,
     public translate: TranslateService,
     private notificationSvc: NotificationService,
     private confirmationService: ConfirmationService,
     private cookieService: CookieService,
     private groupService: GroupsService,
     private location: Location,
     private domSanitizer: DomSanitizer
   ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      const v = new Variables();
      const p = localStorage.getItem('ups');
      if (p != null) {
        const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
        const perm = JSON.parse(val);
        if (perm.includes('USM.Administration.Full')) {
            this.isadm = true;
        }
      }

      this.user = JSON.parse(localStorage.getItem('user'));

      this.EmpId = this.user.employeeId;
      this.FirstName = this.user.firstName;
      this.LastName = this.user.lastName;
      this.Email = this.user.email;
      this.Phone = this.user.phone;
      this.Title = this.user.userTitle;
      this.language = this.user.userLanguage;

      translate.get('GENERAL.ENGLISH').subscribe(en => {
        translate.get('GENERAL.FRENCH').subscribe(fr => {
          this.languages = [
            {
              label: en,
              value: 'en'
            },
            {
              label: fr,
              value: 'fr'
            }
           ];
        });
      });

      if (this.user.userImage !== undefined && this.user.userImage != null) {
        this.Icon = this.user.userImage;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(this.user.userImage);
      }
    }

   async ngOnInit() {
    this.loading = true;
    this.getDepartments();
    await this.getGroups();
   }

   async getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gs.push(res[i]);
      }
      this.arrangeRoles();
    }
    this.loading = false;
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.user.typeRoleGroups !== null && this.user.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ad = true;
          for (const val of this.user.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
        }
      }
    }
  }

   async getGroups2() {
    this.loading = true;
    const uid = this.user.userId;
    let mygroups = '';
    let username = '';
    let groupids = '';

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;

    const usergroups = await this.userService.getUserGroupsByGroupIdUserId(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    this.issave = true;
    if (mygroups.includes('Administrators-Full_Group')) {
      // const groups = localStorage.getItem('my-groups');
      if (groups != null && groups.includes('Administrators-Full_Group')) {
        this.issave = true;
      } else {
        this.issave = false;
      }
    }

    if (mygroups.includes('Administrators-Basic_Group')) {
      // const groups = localStorage.getItem('my-groups');
      if (groups != null && (groups.includes('Administrators-Basic_Group') || groups.includes('Administrators-Full_Group'))) {
        this.issave = true;
      } else {
        this.issave = false;
      }
    }

    this.loading = false;
  }

   loadDept() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.departments.length; i++) {
     if (this.departments[i].value != null) {
       if (this.departments[i].value.departmentId === this.user.departmentId) {
         this.department = this.departments[i].value;
       }
     }
    }

    if (this.department === undefined || this.department === null) {
      this.departments = this.tempdepts;
      this.department = this.tempdepts[0].value;
      this.issave = false;
    }
   }

   getDepartments() {
     this.loading = true;
     // tslint:disable-next-line: deprecation
     this.departmentService.getDepartments().subscribe((res) => {
       if (res != null) {
          const v = new Variables();
          const val = localStorage.getItem('oi');
          const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

          const gis = localStorage.getItem('gi');
          const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
          const gids = JSON.parse(gs);

          const orgId = +oi;
          // const orgId = +localStorage.getItem('organisation-id');
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            if (res[i].organisationId === orgId) {
              if (this.user.departmentId === res[i].departmentId) {
                this.tempdepts.push({label: res[i].departmentName, value: res[i]});
              }
              if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
                let show = false;
                for (const va of res[i].typeRoleGroups) {
                  if (va.typeRoleGroup.roleName === 'Initiator' && gids.includes(va.typeRoleGroup.groupId)) {
                    show = true;
                  }
                }

                if (this.isadm === true) {
                  show = true;
                }

                if (show === false) {
                  continue;
                }
              } else {
                if (this.isadm === true) {
                  // add trigger to list
                } else {
                  continue;
                }
              }
              this.departments.push({label: res[i].departmentName, value: res[i]});
            }
          }
          this.loadDept();
       }
       this.loading = false;
     });
   }

   onCancel() {
    //  this.router.navigateByUrl('/users');
     this.location.back();
   }

   onSave() {
    if (this.EmpId === undefined || this.EmpId.toString().trim() === null || this.EmpId === 0 || this.EmpId.toString().trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTEREMPLOYEEID').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    } else if (isNaN(this.EmpId)) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTEREMPLOYEEIDMUSTBENUMBER').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERFIRSTNAME').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERLASTNAME').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (this.Email === undefined || this.Email === null || this.Email.trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTEREMAIL').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (!this.Email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INVALIDEMAIL').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Phone === undefined || this.Phone === null || this.Phone.trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERPHONE').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (this.Title === undefined || this.Title === null || this.Title.trim() === '') {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ENTERTITLE').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }
    if (this.department === undefined || this.department === null) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTDEPARTMENT').subscribe((resp: string) => {
      this.confirm(resp);
    });
    return;
    }

    this.isSave = true;
    this.loading = true;

    const upt = new UserUpdater();
    upt.Id = this.user.userId;
    upt.Values = [];

    const empid = new KeyValue();
    empid.key = 'emp_id';
    empid.Value = this.EmpId.toString();
    upt.Values.push(empid);

    const fname = new KeyValue();
    fname.key = 'f_name';
    fname.Value = this.FirstName;
    upt.Values.push(fname);

    const lname = new KeyValue();
    lname.key = 'l_name';
    lname.Value = this.LastName;
    upt.Values.push(lname);

    const email = new KeyValue();
    email.key = 'email';
    email.Value = this.Email;
    upt.Values.push(email);

    const phone = new KeyValue();
    phone.key = 'phone';
    phone.Value = this.Phone;
    upt.Values.push(phone);

    const lang = new KeyValue();
    lang.key = 'language';
    lang.Value = this.language;
    upt.Values.push(lang);

    const title = new KeyValue();
    title.key = 'title';
    title.Value = this.Title;
    upt.Values.push(title);

    const dept = new KeyValue();
    dept.key = 'dept';
    dept.Value = this.department.departmentId;
    upt.Values.push(dept);

    if (this.Icon !== undefined && this.Icon !== null && this.Icon.trim() !== '') {
      const image = new KeyValue();
      image.key = 'image';
      image.Value = null;

      image.Value = this.Icon;
      upt.Values.push(image);
    }

    const rgroups = [];
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const val of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.ParentId = this.user.userId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'user';

        rgroups.push(rgroup);
      }
    }

    const data = new TypeRoleGroupData();
    data.ParentId = this.user.userId;
    data.RoleParentType = 'user';
    data.TypeRoleGroup = rgroups;

    const rg = new KeyValue();
    rg.key = 'role_groups';
    rg.Value = JSON.stringify(data);
    upt.Values.push(rg);

    // tslint:disable-next-line: deprecation
    this.userService.putUser(this.user.userId, upt).subscribe((res) => {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.USERUPDATED').subscribe((resp: string) => {
      this.notificationSvc.info('', resp, 2000);
    });
    this.isSave = false;
    this.loading = false;
    this.location.back();
     }, err => {
      this.isSave = false;
      this.loading = false;
      this.handleError(err);
    });
   }

   onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

   confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

   handleError(err) {
    this.isSave = false;
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_user_emp_id_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMPLOYEEIDALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else {
      console.log(err);
    }
  }

}
