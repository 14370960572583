import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class User {
    OrgId: number;
    UserId: number;
    EmployeeId: number;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    ConfCode: string;
    LastLogin: Date;
    Created: Date;
    Password: string;
    Pin: string;
    DepartmentId: number;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
    UserImage: string;
    UserLanguage: string;
    UserTitle: string;
}
